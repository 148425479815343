import React, { useState } from 'react';
import AccountsFirsTransactionsTable from './AccountsFirsTransactionsTable';
import { useFirsTransactions } from '../../../../../hooks/accounts/firs/useFirsTransactions';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { firsTransactionsHeader } from '../../../../../utils/accounts/helpers';

const AccountsFirsTransactionsBody = () => {
  const {
    count,
    lists,
    listsCache,
    searchFirsTable,

    getFirsReverseTransactions,
    isGetReverseLoading,
    ishotspotUploading,

    isEutelsatUploading,
    isReverseLoading,
    reverseTransaction,
    isFullReverseLoading,
    reverseFullTransaction,
  } = useFirsTransactions('reverse');

  //   const [date, setDate] = useState(new Date().toISOString().split('T')[0]);

  //   const [file, setFile] = useState({ name: '' });
  const [reverseFile, setReverseFile] = useState({ name: '' });

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4 ml-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-between w-100 mb-3">
            <div className="d-flex">
              <input
                className="form-control mr-auto empty pl-2"
                type="text"
                placeholder="Search by name, email, product ID, product Name"
                name="optionValue"
                onChange={(e) => {
                  searchFirsTable(e.target.value);
                }}
                style={{
                  width: '300px',
                  height: '40px',
                  border: '1px solid gray',
                  borderRadius: '5px',
                }}
                readOnly={false}
              />
            </div>
          </div>

          {/* <div className="col-12 d-flex justify-content-between w-100 mb-3">
            <div className="d-flex">
              <input
                className="form-control mr-auto empty pl-2"
                type="date"
                placeholder="Search by date"
                name="date"
                onChange={(e) => setDate(e.target.value)}
                style={{
                  width: '300px',
                  height: '40px',
                  border: '1px solid gray',
                  borderRadius: '5px',
                }}
              />

              <button onClick={() => getAllDedicatedInvoice(date)} className="btn btn-green shadow-md ml-3">
                Search date
              </button>
            </div>
          </div> */}

          {/* <div className="col-12 d-flex mt-4">
            <input
              className="form-control empty pl-2"
              type="file"
              placeholder="upload csv"
              name="form"
              accept=".csv"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              style={{
                width: '300px',
                height: '40px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
            />

            <button
              disabled={isEutelsatUploading || ishotspotUploading}
              onClick={() => handleCsvUpload(getAllDedicatedInvoice, file)}
              className="btn btn-green shadow-md ml-3"
            >
              {ishotspotUploading ? 'uploading...' : 'Upload Hotspot Data'}
            </button>
          </div> */}
          <div className="col-12 d-flex mt-4">
            <input
              className="form-control empty pl-2"
              type="file"
              placeholder="upload csv"
              name="form"
              accept=".csv"
              onChange={(e) => {
                setReverseFile(e.target.files[0]);
              }}
              style={{
                width: '300px',
                height: '40px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
              readOnly={false}
            />

            <button
              disabled={isEutelsatUploading || ishotspotUploading}
              onClick={() => getFirsReverseTransactions(reverseFile)}
              className="btn btn-green shadow-md ml-3"
            >
              {isGetReverseLoading ? 'loading...' : 'Upload Csv'}
            </button>
          </div>

          <div className="col-12 d-flex mt-2">
            <CustomCsvDownload
              data={listsCache || []}
              headers={firsTransactionsHeader}
              filename={`Firs Transactions`}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download Data"
            />
          </div>
          <div className="p-0 col-12 d-flex justify-content-between mt-5 ml-3">
            {listsCache && listsCache.length > 0 ? (
              <div>
                Transaction Count: <span className="badge badge-secondary mr-4">{count}</span>
              </div>
            ) : null}
          </div>
          <AccountsFirsTransactionsTable
            lists={lists}
            isLoading={isGetReverseLoading}
            isReverseLoading={isReverseLoading}
            reverseTransaction={reverseTransaction}
            reverseFile={reverseFile}
            isFullReverseLoading={isFullReverseLoading}
            reverseFullTransaction={reverseFullTransaction}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountsFirsTransactionsBody;
