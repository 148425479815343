import React, { useState } from 'react';

import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import loader from '../../../../../../src/assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../utils/helpers';


const AccountsFirsReversedTransactionsTable = ({ reversedTransactions, loadingTransactions, date }) => {

  const tableData =
    reversedTransactions && reversedTransactions.length > 0 ? (
      reversedTransactions
        .map((item, i) => {
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{item.declarationId}</td>
              <td>{item.reference}</td>
              <td>{item.email ? item.email : 'N/A'}</td>
              <td>{item.amount ? `N${item.amount}` : 'N/A'}</td>
              <td>{item.returnMessage}</td>
              <td>{helpers.formatDateAndTimes(item.createdAt)}</td>
              {/* <td>{helpers.formatDateAndTimes(item.updatedAt)}</td> */}
            </tr>
          );
        })
        .reverse()
    ) : (
      <NoItemRow colSpan={7} text="There are no records" />
    );
  return (
    <div className="table-responsive content col-12 px-1">
      <table className="table">
        <thead>
          <tr className="">
            <th>#</th>
            <th className="text-nowrap">Declaration ID</th>
            <th className="text-nowrap">Reference</th>
            <th className="text-nowrap">Email</th>
            <th className="text-nowrap">Amount</th>
            <th className="text-nowrap"> Return Message</th>
            <th className="text-nowrap"> Date Created</th>
            {/* <th className="text-nowrap"> Date Updated</th> */}
          </tr>
        </thead>
        <tbody>
          {loadingTransactions ? (
            <tr>
              <td className="text-center">
                <img src={loader} alt="loading gif" />
              </td>
            </tr>
          ) : (
            tableData
          )}
        </tbody>
      </table>

    </div>
  );
};

export default AccountsFirsReversedTransactionsTable;


