/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import toastr from 'toastr';
import { API_URL } from '../../../utils/config';
import axios from 'axios';

export const useFirsTransactions = (type) => {
  const [firsData, setFirsData] = useState({
    lists: [],
    listsCache: [],
    count: 0,
    names: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [ishotspotUploading, setIshotspotUploading] = useState(false);
  const [isEutelsatUploading, setIsEutelsatUploading] = useState(false);
  const [isGetReverseLoading, setIsGetReverseLoading] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [reversedTransactions, setReversedTransactions] = useState([]);

  const [isReverseLoading, setIsReverseLoading] = useState(false);
  const [isFullReverseLoading, setIsFullReverseLoading] = useState(false)


  const [isManualPosting, setIsManualPosting] = useState(false)

  const handleCsvUpload = async (reload, hotspotFile) => {
    const form = new FormData();

    if (!hotspotFile) {
      toastr.info('No file uploaded');
      return;
    }

    setIshotspotUploading(true);

    try {
      form.append('form', hotspotFile);

      const res = await fetch(`${API_URL}/firs/bulkupload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: form,
      });

      if (res.status === 200) {
        toastr.success('File uploaded successfully');
        reload();
      }
    } catch (error) {
      toastr.error('Something went wrong...');
    } finally {
      setIshotspotUploading(false);
      // setFile('');
    }
  };
  const handleEutelsatCsvUpload = async (reload, eutelFile) => {
    const form = new FormData();

    if (!eutelFile) {
      toastr.info('No file uploaded');
      return;
    }

    setIsEutelsatUploading(true);

    try {
      form.append('form', eutelFile);

      const res = await fetch(`${API_URL}/firs/uploadEutelsat`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: form,
      });

      if (res.status === 200) {
        toastr.success('File uploaded successfully');
        reload();
      }
    } catch (error) {
      toastr.error('Something went wrong...');
    } finally {
      setIsEutelsatUploading(false);
      // setEutelsatFile('');
    }
  };



  const getAllDedicatedInvoice = async (date) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/firs/get_transactions?date=${date}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setFirsData({
        ...firsData,
        count: data.length,
        lists: data,
        listsCache: data,
      });
    } catch (e) {
      toastr.info('Something went wrong!');
    }
    setIsLoading(false);
  };

  const getFirsReverseTransactions = async (file) => {
    const form = new FormData();

    if (!file) {
      toastr.info('No file uploaded');
      return;
    }

    setIsGetReverseLoading(true);
    try {
      form.append('form', file);

      const res = await fetch(`${API_URL}/firs/getReverseTransactions`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: form,
      });

      if (res.status === 200) {
        const data = await res.json();
        setFirsData({
          ...firsData,
          count: data.data.length,
          lists: data.data,
          listsCache: data.data,
          names: data.names,
        });
      }
    } catch (error) {
    } finally {
      setIsGetReverseLoading(false);
    }
  };

  const getFirsReversedTransactions = async (date) => {
    setLoadingTransactions(true);
    const config = {
      method: 'get',
      url: `${API_URL}/firs/getReversedTransactions?date=${date}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    axios(config)
      .then((response) => {

        if (response.data.status) {

          setReversedTransactions(response.data.data);
        }
        setLoadingTransactions(false);
      })
      .catch((e) => {
        toastr.info(e.response.data.message);
        setLoadingTransactions(false);
      });
  };

  const reloadReverseTransactions = async (file) => {
    const form = new FormData();
    try {
      form.append('form', file);
      const res = await fetch(`${API_URL}/firs/getReverseTransactions`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: form,
      });

      if (res.status === 200) {
        const data = await res.json();
        setFirsData({
          ...firsData,
          count: data.data.length,
          lists: data.data,
          listsCache: data.data,
          names: data.names,
        });
      }
    } catch (error) { }
  };

  const reverseTransaction = async (id, reverseAmount, file) => {
    // if (Number(reverseAmount) > Number(totalAmount)) {
    //   toastr.error('Reverse Amount cannot be greater than Total Amount');
    //   return;
    // }

    setIsReverseLoading(true);
    try {
      const url = `${API_URL}/firs/reverseTransaction`;

      const response = await axios({
        method: 'POST',
        url,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        data: {
          id,
          amount: reverseAmount,
        },
      });

      if (response.status === 200) {
        toastr.success('Transaction reversed successfully');
        if (type && type == 'reverse') {
          reloadReverseTransactions(firsData.names);
        } else {
          getAllDedicatedInvoice();
        }
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    } finally {
      setIsReverseLoading(false);
    }
  };

  const handleManualPosting = async (name, amount, date, reference, product, description) => {
    setIsManualPosting(true);

    try {

      const url = `${API_URL}/firs/manualPosting`;
      const response = await axios({
        method: 'POST',
        url,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        data: {
          name,
          amount,
          date,
          reference,
          product,
          trans_description: description
        },
      });

      if (response.status === 200) {
        toastr.success('Transaction posted successfully');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    } finally {
      setIsManualPosting(false);
    }
  };

  const reverseFullTransaction = async (reference, email) => {
    // if (Number(reverseAmount) > Number(totalAmount)) {
    //   toastr.error('Reverse Amount cannot be greater than Total Amount');
    //   return;
    // }

    setIsFullReverseLoading(true);
    try {
      const url = `${API_URL}/firs/reversal`;

      const response = await axios({
        method: 'POST',
        url,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        data: {
          reference: reference.trim(),
          email: email,
        },
      });

      if (response.status === 200) {
        toastr.success('Transaction reversed successfully');
        if (type && type == 'reverse') {
          reloadReverseTransactions(firsData.names);
        } else {
          getAllDedicatedInvoice();
        }
      }
    } catch (error) {
      toastr.info('Something went wrong!');
    } finally {
      setIsFullReverseLoading(false);
    }
  };

  const searchFirsTable = (search) => {
    if (firsData.listsCache) {
      if (search === '') {
        setFirsData({
          ...firsData,
          lists: firsData.listsCache,
        });
        return;
      }

      const searchtoLowerCase = search.toLowerCase();
      const newItems = firsData.listsCache.filter(
        (item) =>
          item.name.toLowerCase().includes(searchtoLowerCase) ||
          item.email.toLowerCase().includes(searchtoLowerCase) ||
          item.companyProductID.toLowerCase().includes(searchtoLowerCase) ||
          item.companyProductName.toLowerCase().includes(searchtoLowerCase),
      );
      setFirsData({
        ...firsData,
        lists: newItems,
      });
    }
  };

  useEffect(() => {
    if (!(type && type == 'reverse')) {
      let date = new Date();
      date = date.toISOString().split('T')[0];
      getAllDedicatedInvoice(date);
    }
  }, []);

  return {
    ...firsData,
    isLoading,
    ishotspotUploading,
    isEutelsatUploading,
    searchFirsTable,
    isGetReverseLoading,
    getFirsReverseTransactions,
    handleCsvUpload,
    handleEutelsatCsvUpload,
    getAllDedicatedInvoice,
    isReverseLoading,
    reverseTransaction,
    reversedTransactions,
    loadingTransactions,
    getFirsReversedTransactions,
    isFullReverseLoading,
    reverseFullTransaction,
    handleManualPosting,
    isManualPosting,





  };
};
