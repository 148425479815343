import React, { useEffect, useState, useRef } from 'react';

import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import loader from '../../../../../../src/assets/img/icons/TizetiLoaderColored.gif';
import { API_URL } from '../../../../../utils/config';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

export default function InvoicesBody() {
  const [lists, setLists] = useState([]);

  const [view, setView] = useState(false);

  const [editType, setEditType] = useState('');

  const [recordId, setRecordId] = useState(null);

  const [isLoading, setIsloading] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);

  const [editDetails, setEditDetails] = useState({});

  const [file, setFile] = useState({ name: '' });

  const getWificallList = async (e, csv) => {
    e.preventDefault();
    const form = new FormData();

    if (!csv.name) {
      toast.error('CSV file not uploaded');
      return;
    }
    setIsloading(true);
    form.append('form', csv);

    const url = `${API_URL}/planList/getWificallInvoiceDetails`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      body: form,
    });

    if (response.ok) {
      const data = await response.json();

      setLists([...data]);
      setIsloading(false);
      return;
    }

    toast.error('Something went wrong...');
    setIsloading(false);
  };

  const sendInvoices = async (e) => {
    e.preventDefault();
    if (lists.length < 1) {
      toast.error('Table is empty');
      return;
    }

    setSendingMail(true);
    const url = `${API_URL}/planList/sendWificallInvoiceEmails`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        data: lists,
      }),
    });

    if (response.ok) {
      toast.success('Emails sent successfully');
      setSendingMail(false);
      return;
    }

    toast.info('Emails not sent, Try again letter');
    setSendingMail(false);
  };

  const fetchData = async () => {
    setIsloading(true);
    const url = `${API_URL}/planList/getWificallInvoiceData`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const data = await response.json();

      setLists([...data]);
      setIsloading(false);
      return;
    }

    toast.error('Something went wrong...');
    setIsloading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);
  return (
    <main className="container-fluid col-12 my-2">
      {/* <form className="form-inline pl-4" onSubmit={(e) => getWificallList(e, file)}>
        <div className="col-12 d-flex mt-4">
          <input
            className="form-control empty pl-2"
            type="file"
            placeholder="upload csv"
            name="form"
            accept=".csv"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
            style={{
              width: '300px',
              height: '40px',
              border: '1px solid gray',
              borderRadius: '5px',
            }}
            readOnly={false}
          />

          <button disabled={isLoading} className="btn btn-green shadow-md ml-3">
            {isLoading ? 'loading...' : 'Upload Wificall Users'}
          </button>
        </div>
      </form> */}

      <div className="col-12 d-flex mt-4 pl-4">
        <button
          className="btn btn-green shadow-md ml-3 cursor-pointer"
          onClick={() => {
            setView(true);
            setEditType('add');
            setEditDetails({});
          }}
        >
          Add Invoice Details
        </button>
      </div>

      {/* {lists.length > 0 && (
        <div className="col-12 d-flex mt-4 pl-4">
          <button disabled={sendingMail} onClick={sendInvoices} className="btn btn-green shadow-md ml-3">
            {sendingMail ? 'sending...' : 'Send Invoices'}
          </button>
        </div>
      )} */}

      <InvoicesTable
        isLoading={isLoading}
        lists={lists}
        setView={setView}
        setEditType={setEditType}
        setRecordId={setRecordId}
        fetchData={fetchData}
        setEditDetails={setEditDetails}
      />
      <AddInvoice
        view={view}
        setView={setView}
        fetchData={fetchData}
        editType={editType}
        recordId={recordId}
        editDetails={editDetails}
      />
    </main>
  );
}

function InvoicesTable({ isLoading, lists, setView, setEditType, setRecordId, fetchData, setEditDetails }) {
  const [loading, setLoading] = useState(false);
  const handleDelete = async (id) => {
    setLoading(true);
    const url = `${API_URL}/planList/changeWificallInvoiceDetails`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, type: 'delete' }),
    });

    if (response.ok) {
      toast.success('Details Deleted');
      fetchData();
    }
    setLoading(false);
  };
  const tableData =
    lists && lists.length > 0 ? (
      lists.map((item, i) => {
        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{item.company}</td>
            <td>{item.email}</td>
            <td>{item.address || 'N/A'}</td>
            <td>{item.month}</td>
            <td>{item.charge}</td>

            <td>
              <button
                onClick={() => {
                  setView(true);
                  setEditType('edit');
                  setRecordId(item.id);
                  setEditDetails({ ...item });
                }}
                className="btn btn-info shadow-md ml-3"
              >
                Edit
              </button>
            </td>
            <td>
              {' '}
              <button
                onClick={() => {
                  handleDelete(item.id);
                }}
                disabled={loading}
                className="btn btn-danger shadow-md ml-3"
              >
                {loading ? 'loading..' : 'Delete'}
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan={6} text="There are no records" />
    );
  return (
    <div className="table-responsive content col-12 px-1 mt-5">
      <table className="table">
        <thead>
          <tr className="">
            <th>#</th>
            <th className="text-nowrap">Name</th>
            <th className="text-nowrap"> Email</th>
            <th className="text-nowrap">Address</th>
            <th className="text-nowrap">Quantity</th>
            <th className="text-nowrap">Charge</th>

            <th className="text-nowrap"></th>
            <th className="text-nowrap"></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td className="text-center">
                <img src={loader} alt="loading gif" />
              </td>
            </tr>
          ) : (
            tableData
          )}
        </tbody>
      </table>
    </div>
  );
}

function AddInvoice({ view, setView, fetchData, editType, recordId, editDetails }) {
  const handleClose = () => {
    setView(false);
  };

  const ref = useRef();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!ref.current) return;

    setLoading(true);
    const form = new FormData(ref.current);

    const body = {
      company: form.get('company'),
      email: form.get('email'),
      charge: Number(form.get('charge')),
      month: Number(form.get('month')),
      address: form.get('address'),
    };

    if (editType === 'edit') {
      for (let i in body) {
        if (!body[i]) {
          delete body[i];
        }
      }
      body.type = editType;
      if (recordId) body.id = recordId;
    }

    const url =
      editType == 'add'
        ? `${API_URL}/planList/addWificallInvoiceDetails`
        : `${API_URL}/planList/changeWificallInvoiceDetails`;

    const response = await fetch(url, {
      method: editType === 'edit' ? 'PATCH' : 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      fetchData();
    }
    setLoading(false);
    setView(false);
  };

  const { company = '', email = '', address = '', month = '', charge = '' } = editDetails;

  return (
    <Modal show={view} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Add Details</Modal.Title>
      </Modal.Header>

      <form ref={ref} onSubmit={handleSubmit} className="container">
        <div className="col-12 mb-3">
          <div htmlFor="" className="my-2">
            Company
          </div>
          <div className="d-flex">
            <input
              className="form-control mr-auto empty"
              type="text"
              placeholder="Company"
              defaultValue={company}
              name="company"
              style={{
                width: '300px',
                // height: '40px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
              required={editType === 'add'}
            />
          </div>
        </div>

        <div className="col-12 w-100 mb-3">
          <div htmlFor="" className="my-2">
            Email
          </div>
          <div className="d-flex">
            <input
              className="form-control mr-auto empty"
              type="email"
              placeholder="Email"
              defaultValue={email}
              name="email"
              style={{
                width: '300px',
                height: '40px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
              required={editType === 'add'}
            />
          </div>
        </div>

        {editType === 'edit' && (
          <div className="col-12 w-100 mb-3">
            <div htmlFor="" className="my-2">
              Address
            </div>
            <div className="d-flex">
              <input
                className="form-control mr-auto empty"
                type="text"
                placeholder="Address"
                name="address"
                defaultValue={address}
                style={{
                  width: '300px',
                  height: '40px',
                  border: '1px solid gray',
                  borderRadius: '5px',
                }}
              />
            </div>
          </div>
        )}

        <div className="col-12 w-100 mb-3">
          <div htmlFor="" className="my-2">
            Charge
          </div>
          <div className="d-flex">
            <input
              className="form-control mr-auto empty"
              type="number"
              placeholder="Charge"
              name="charge"
              defaultValue={charge}
              style={{
                width: '300px',
                height: '40px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
              required={editType === 'add'}
            />
          </div>
        </div>

        <div className="col-12 w-100 mb-3">
          <div htmlFor="" className="my-2">
            Month Quantity
          </div>
          <div className="d-flex">
            <input
              className="form-control mr-auto empty"
              type="number"
              placeholder="Month Quantity"
              name="month"
              defaultValue={month}
              style={{
                width: '300px',
                height: '40px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
              required={editType === 'add'}
            />
          </div>
        </div>

        <div className="text-center my-3">
          {loading ? (
            <button type="button" className="btn btn-green py-2 px-5 p-28" disabled>
              loading..
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5 p-2">
              Submit
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
}
