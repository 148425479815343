import React, { useEffect, useState } from 'react';

import { useFirsTransactions } from '../../../../../hooks/accounts/firs/useFirsTransactions';

import CustomInput from '../../../shared/forms/CustomInput';
import AccountsFirsReversedTransactionsTable from './AccountsFirsReversedTransactionsTable';

const AccountsReversedFirsTransactionsBody = () => {
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);

  const [date, setDate] = useState(formattedDate);
  const { reversedTransactions, loadingTransactions, getFirsReversedTransactions } = useFirsTransactions();

  const onSubmit = async (e) => {
    e.preventDefault();
    getFirsReversedTransactions(date);
  };

  useEffect(() => {
    getFirsReversedTransactions(date);
  }, []);

  const submitForm = () => {
    return date.length > 0;
  };

  return (
    <div className="container-fluid pt-5">
      <form className="form-inline mt-4 inner-addon d-flex" onSubmit={onSubmit}>
        <div className="form-group mx-2">
          <label className="mt-2 p-0 copy-font">Select Date: </label>
          <CustomInput
            classname="form-control mr-2"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type="date"
          />
        </div>

        <div className="mb-3">
          {loadingTransactions ? (
            <button className="btn btn-green" style={{ height: '39px' }} disabled>
              Searching
            </button>
          ) : (
            <button className="btn btn-green" style={{ height: '39px' }} disabled={!submitForm()}>
              Search
            </button>
          )}
        </div>
      </form>

      <AccountsFirsReversedTransactionsTable
        loadingTransactions={loadingTransactions}
        reversedTransactions={reversedTransactions}
        date={date}
      />
    </div>
  );
};

export default AccountsReversedFirsTransactionsBody;
